.menu {
  background-color: var(--second-background-color);
  color: var(--second-color);
  position: sticky;
  top: 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  .top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    user-select: none;

    .links-menu-switcher {
      font-weight: bold;
      font-size: 1.3em;
      cursor: pointer;
      margin: 1rem;
      margin-top: 1.5rem;
    }

    .username {
      margin: 1rem;
      font-weight: 500;
    }
  
    .exit {
      margin: 1rem;
      margin-top: 1.5rem;
      font-weight: 500;
      font-size: 1.3em;
      cursor: pointer;
  
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .links {
    position: absolute;
    background-color: var(--second-background-color);
    top: 5rem;
    left: -100%;
    max-width: 30rem;
    width: 100%;
    height: calc(100vh - 5rem);
    transition: left 0.2s;

    &-open {
      left: 0;
    }

    .link {
      display: block;
      padding: 1rem;
      cursor: pointer;
  
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .menu .links {
    max-width: 100%;
  }
}
