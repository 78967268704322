.wrapper {
  display: grid;
  gap: 20px;
}

.button {
  height: 3.3rem !important;
}

.textarea {
  padding: 10px !important;
}

.linkWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;
  height: 120px;
  overflow: hidden;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.linkTittle {
  font-size: 16px;
  font-weight: 600;
}

.linkDescription {
  font-size: 0.9em;
}

.prewview {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 520px;
  margin: 0 auto;
}

.modalBanner {
  width: 100%;
  object-fit: contain;
  font-size: 0;
}