.list__item {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 20rem 1fr 4.2rem;

  img {
    width: 100%;
  }

  .list__item-delete {
    cursor: pointer;
    font-weight: bold;
  }
}