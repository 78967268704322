.primary, html {
  --main-color: #292b2c;
  --second-color: #fff;
  --background-color: #fff;
  --second-background-color: #0275d8;
  --error-color: #d9534f;
  --border-color: #d9d9d9;
  --warning-color: #f0ad4e;
  font-size: 10px;
}
