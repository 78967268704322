.create-or-edit__wrapper-link {
  padding-bottom: 2rem;

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.create-or-edit__filters {
  padding-bottom: 2rem;
}

.create-or-edit__filters-header {
  display: grid;
  grid-template-columns: 15rem 1fr;
  align-items: center;
  padding-bottom: 2rem;
}

.create-or-edit__filters-header-drop {
  margin-left: auto;
  font-weight: bold;
  cursor: pointer;
}

.create-or-edit__filters-items {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.create-or-edit__filters-items-items {
  max-height: 300px;
  overflow: auto;
  padding: 2rem;
  border: 1px solid var(--border-color);
}

.create-or-edit__filters-items {
  font-weight: bold;
  padding-bottom: 1rem;
}

.create-or-edit__flags {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.create-or-edit__mini-list {
  max-height: 150px;
  overflow: auto;
  
  span {
    padding-right: 1rem;
    cursor: pointer;
    font-weight: bold;
  }
}

.promo-code__list {
  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    td, th {
      border-bottom: 0.1rem solid var(--border-color);
    }

    td {
      cursor: pointer;
    }
  }

  a {
    display: block;
    padding: 0.7rem 0;
  }
}