.order-product {
  padding-top: 3rem;

  .product-total {
    font-size: 1.1em;
    text-align: right;
  }

  .product-list {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  
    td, th {
      padding: 0.7rem 0;
      border-bottom: 0.1rem solid var(--border-color);
    }
  
    // td {
    //   cursor: pointer;
    // }

    .product-item {
      margin: 1rem 0;
    }

    .old-price {
      text-decoration: line-through;
      margin-right: 1rem;
    }
  }

  a {
    display: block;
  }
}
