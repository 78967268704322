.input-with-label {
  display: block;
  
  .label {
    padding-bottom: 0.5rem;
    font-weight: 500;
  }
}

.input-with-label-checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}