.default-layout {
  position: relative;
  height: 100%;

  .menu {
    z-index: 100;
  }

  .main-block {
    height: calc(100% - 5rem);
    margin: 0 auto;
    padding: 1rem;
    z-index: 1;
    max-width: 140rem;
  }
}
