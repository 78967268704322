.section-page {
  margin: 2rem 0;
}

.section-page__header {
  display: flex;
  justify-content: space-between;
  font-size: 1.6em;
  margin-bottom: 0.8rem;
  margin-top: 0.1rem;
}

.section-page__header-delete {
  cursor: pointer;
  font-size: 0.6em;
  font-weight: bold;
}