.button {
  button {
    display: block;
    outline: none;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    border: 0.1rem solid var(--background-color);
    padding: 0 1rem;
    height: 3rem;
    color: var(--second-color);
    background-color: var(--second-background-color);
    cursor: pointer;
    border-radius: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .error {
    // border-color: var(--error-color);
  }

  .error-text {
    color: var(--error-color);
    padding-top: 0.3rem;
    font-size: 0.9em;
  }
}
