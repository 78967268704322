.order {
  .description {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 2rem;
    padding-top: 1rem;
    gap: 2rem;
    align-items: center;

    .description-title {
      font-size: 1.3em;
      font-weight: 500;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  
    .block {
      gap: 1rem 0;
      display: flex;
      flex-direction: column;
  
      .title {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.4em;
      }
    }
  }
}
