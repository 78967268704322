.orders {
  height: 100%;

  &.noticed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loader {
    font-size: 40px;
    color: var(--second-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .orders-list {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 10px;
    td, th {
      border-bottom: 0.1rem solid var(--border-color);
      text-align: center;

      a {
        display: block;
        height: 100%;
        padding: 1.3rem 0;
        text-align: center;
      }
    }
  }

  .order-item {
    margin: 1rem 0;

    .link {
      display: block;
    }
  }

  .description {
    padding-bottom: 15px;
  }

  select {
    option, & {
      background-color: #E6E6E6;
    }
    option[value="Обработан (handled)"],
    option[value="Открыт (opened)"],
    &[data-value="Обработан (handled)"],
    &[data-value="Открыт (opened)"]
    {
      background-color: #33FF77;
    }
    option[value="Самозабор из шоурума (pickup)"],
    &[data-value="Самозабор из шоурума (pickup)"]
    {
      background-color: #33DDFF;
    }
    option[value="Отложен (withheld)"],
    &[data-value="Отложен (withheld)"]
    {
      background-color: #5598FF;
    }
    option[value="Отклонен (denied)"],
    option[value="Аннулирован (canceled)"],
    &[data-value="Отклонен (denied)"],
    &[data-value="Аннулирован (canceled)"]
    {
      background-color: #FF3737;
    }
    option[value="В процессе доставки (on_delivery)"],
    &[data-value="В процессе доставки (on_delivery)"]
    {
      background-color: #CF99FF;
    }
    option[value="Выполнен (completed)"],
    &[data-value="Выполнен (completed)"]
    {
      background-color: #00AE03;
    }
  }
}

.pagination {
  padding: 2rem 0;
}