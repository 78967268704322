.users {
  height: 100%;

  &.noticed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loader {
    font-size: 40px;
    color: var(--second-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .users-list {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    td, th {
      padding: 0.7rem 0.5rem;
      border-bottom: 0.1rem solid var(--border-color);
    }
  }

  .users-item {
    margin: 1rem 0;

    a {
      display: block;
    }
  }

  .pagination {
    padding: 2rem 0;
  }
}

.users__search {
  display: grid;
  grid-template-columns: 1fr 30rem;
  gap: 3rem;
  align-items: center;
  padding-bottom: 2rem;
}