.selector {
  display: block;
  outline: none;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  border: 0.1rem solid var(--border-color);
  padding: 0 1.1rem;
  height: 3.3rem;
  color: var(--main-color);
  background-color: var(--background-color);
  border-radius: 0.2rem;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
