.user .loader {
  font-size: 40px;
  color: var(--second-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user-page__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

}

.user-page__header {
  padding-bottom: 1rem;
}

.primary-user-info, .additional-user-info {
  display: grid;
  gap: 1rem 0;
}

.link-btn {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  border: 0.1rem solid var(--background-color);
  padding: 0 1rem;
  height: 3rem;
  color: var(--second-color);
  background-color: var(--second-background-color);
  cursor: pointer;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
}

.additional-user-info__title {
  font-weight: 500;
  font-size: 1.3em;
}