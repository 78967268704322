@import "./color-variants";
@import "./helpers.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--background-color);
  color: var(--main-color);
  font-size: 1.4em;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.app {
  height: 100%;

  .app__loader {
    font-size: 40px;
    color: var(--second-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.table-default {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 10px;
  td, th {
    border-bottom: 0.1rem solid var(--border-color);
    // text-align: center;

    a {
      display: block;
      height: 100%;
      padding: 1.3rem 0;
      text-align: center;
    }
  }

  td {
    padding: 5px;
  }
}