.not-found-mobile-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;

  .content {
    text-transform: uppercase;
    text-align: center;
  }

  .text {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  .link {
    font-weight: 700;
  }
}
