.wrapper {
  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    td, th {
      border-bottom: 0.1rem solid var(--border-color);
    }

    th {
      padding-bottom: 0.7rem;
    }

    td {
      cursor: pointer;
    }
  }

  a {
    display: block;
    padding: 0.7rem 0;
  }
}