.allCount {
  margin-bottom: 10px;
}

.device, .allCount {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &:last-child {
    margin-bottom: 0;
  }
}

.tdImage {
  width: 200px;
}

.image {
  max-width: 100%;
  max-height: 200px;
}