.table-list {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th {
    padding: 0.7rem 0;
  }
  
  td, th {
    border-bottom: 0.1rem solid var(--border-color);

    a {
      padding: 0.7rem 0;
    }
  }
}

.table-item {
  margin: 1rem 0;

  .link {
    display: block;
  }
}