.input {
  input, textarea {
    display: block;
    outline: none;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    border: 0.1rem solid var(--border-color);
    padding: 0 1.1rem;
    color: var(--main-color);
    background-color: var(--background-color);
    border-radius: 0.2rem;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  textarea {
    resize: vertical;
  }

  input {
    height: 3.3rem;
  }

  .error {
    border-color: var(--error-color);
  }

  .error-text {
    color: var(--error-color);
    padding-top: 0.3rem;
    font-size: 0.9em;
  }

  .warning {
    border-color: var(--warning-color);
  }

  .warning-text {
    color: var(--warning-color);
    padding-top: 0.3rem;
    font-size: 0.9em;
  }
}


