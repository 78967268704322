.pagination-component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;

  li {
    a {
      display: block;
      cursor: pointer;
      background-color: var(--second-background-color);
      color: var(--second-color);
      padding: 0 1.1rem;
      height: 3.3rem;
      line-height: 3.3rem;
      border-radius: 0.2rem;
    }

    &.selected {
      opacity: 0.7;
      pointer-events: none;
    }
  }
}
